import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Njomanes Revamp </p>
      <p>1394 Sandawane St, Dlamini, Soweto, 1818</p>
    </div>
  );
};

export default Footer;