import React, { useState } from "react";
import "./Header.css";
import Logo from "../../Images/Logo1.png";
const Header = ({ scrollToSection }) => {
  const [menu, setMenu] = useState(false);

  const isHome = 0;
  const isAbout = 0;
  const isServices = 0;
  const isProjects = 0;
  const isTestimonials = 0;
  const isContact = 0;

  const showMenu = () => {
    setMenu(!menu);
  };
  return (
    <div className="Header">
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="burgerIcon"
        onClick={() => showMenu()}
      >
        <path
          d="M4 18L20 18"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M4 12L20 12"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M4 6L20 6"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      <div
        className="sideMenu"
        style={menu ? { display: "inline-block" } : { display: "none" }}
      >
        <div className="onClick">
          <div className="imgs">
            <img src={Logo} alt="Logo" className="logo" />
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="close"
              onClick={() => showMenu()}
            >
              <g id="Menu / Close_LG">
                <path
                  id="Vector"
                  d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <hr />
          <div className="menu2">
            <ul>
              <li
                onClick={() => {
                  scrollToSection("home");
                  showMenu();
                }}
              >
                <h2
                  style={isHome ? { color: "#f19308" } : { color: "#211f20" }}
                >
                  Home
                </h2>
              </li>
              <li
                onClick={() => {
                  scrollToSection("about");
                  showMenu();
                }}
              >
                <h2
                  style={isAbout ? { color: "#f19308" } : { color: "#211f20" }}
                >
                  About
                </h2>
              </li>
              <li
                onClick={() => {
                  scrollToSection("services");
                  showMenu();
                }}
              >
                <h2
                  style={
                    isServices ? { color: "#f19308" } : { color: "#211f20" }
                  }
                >
                  Services
                </h2>
              </li>
              <li
                onClick={() => {
                  scrollToSection("projects");
                  showMenu();
                }}
              >
                <h2
                  style={
                    isProjects ? { color: "#f19308" } : { color: "#211f20" }
                  }
                >
                  Projects
                </h2>
              </li>
              <li
                onClick={() => {
                  scrollToSection("testimonials");
                  showMenu();
                }}
              >
                <h2
                  style={
                    isTestimonials ? { color: "#f19308" } : { color: "#211f20" }
                  }
                >
                  Testimonials
                </h2>
              </li>
              <li
                onClick={() => {
                  scrollToSection("contact");
                  showMenu();
                }}
              >
                <h2
                  style={
                    isContact ? { color: "#f19308" } : { color: "#211f20" }
                  }
                >
                  Contact Us
                </h2>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <img src={Logo} alt="Concrete construction logo" className="frontLogo" />
      <div className="menu">
        <ul>
          <li
            onClick={() => {
              scrollToSection("home");
            }}
          >
            <h2 style={isHome ? { color: "#f19308" } : { color: "#211f20" }}>
              Home
            </h2>
          </li>
          <li
            onClick={() => {
              scrollToSection("about");
            }}
          >
            <h2 style={isAbout ? { color: "#f19308" } : { color: "#211f20" }}>
              About
            </h2>
          </li>
          <li
            onClick={() => {
              scrollToSection("services");
            }}
          >
            <h2
              style={isServices ? { color: "#f19308" } : { color: "#211f20" }}
            >
              Services
            </h2>
          </li>
          <li
            onClick={() => {
              scrollToSection("projects");
            }}
          >
            <h2
              style={isProjects ? { color: "#f19308" } : { color: "#211f20" }}
            >
              Projects
            </h2>
          </li>
          <li
            onClick={() => {
              scrollToSection("testimonials");
            }}
          >
            <h2
              style={
                isTestimonials ? { color: "#f19308" } : { color: "#211f20" }
              }
            >
              Testimonials
            </h2>
          </li>
          <li
            onClick={() => {
              scrollToSection("contact");
            }}
          >
            <h2 style={isContact ? { color: "#f19308" } : { color: "#211f20" }}>
              Contact Us
            </h2>{" "}
          </li>
        </ul>
      </div>
      <div className="phone">
        <p>Call Now</p>
        <p>
          <b>
            <a href="tel:+27787314378">0787314378</a>
          </b>
        </p>
      </div>
    </div>
  );
};

export default Header;